import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Url } from 'src/app/global/global';
import { devUrl } from 'src/app/global/global';
import { Configuration } from 'src/app/global/global-config';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  constructor(private http: HttpClient, private conf: Configuration) {}
  // post privacy text
  postPrivacy(data): Observable<any> {
    console.log(data);
    // let headers = new HttpHeaders();
    // headers.append('lan', lan);
    // headers: request.headers.set('lan', 'en');
    return this.http.post(`${devUrl}htmlPage`, data);
  }

  // get privacy text
  getPrivacy(data): Observable<any> {
    return this.http.get(
      `${devUrl}htmlPage?type=` + data.userType + '&lan=' + data.lan
    );

    // return this.http.get(`${devUrl}privacyPolicy?userType=`+data.userType )
  }
  // post Terms & condition text
  postTerms(data): Observable<any> {
    return this.http.post(`${devUrl}htmlPage`, data);
  }

  // get Terms & condition text
  getTerms(data): Observable<any> {
    return this.http.get(
      `${devUrl}htmlPage?type=` +
        data.userType +
        '&lan=' +
        data.lan
    );
  }

  // get active languge
  languageActive(): Observable<any> {
    return this.http.get(`${Url}language/?status=1`);
  }

  /**
   * @description get all version list
   * @author Jagannath
   * @date 2020-06-02
   * @param status device type
   */
  getVersionList(status): Observable<any> {
    const params = new HttpParams().set('type', status);

    return this.http.get(`${devUrl}appVersion`, { params });
  }
 
  /**
   * @description get all users of a version
   * @author Jagannath
   * @date 2020-06-02
   * @param list query params
   */
  getVersionUsers(list): Observable<any> {
    let params = new HttpParams()
      .set('limit', list.limit)
      .set('offset', list.offset)
      .set('type', list.type)
      .set('appVersion', list.appVersion);

    if (list.searchText) {
      params = params.set('searchText', list.searchText);
    }

    return this.http.get(`${devUrl}appVersion/userListByVersion`, { params });
  }
  
  /**
   * @description change version mandatory status
   * @author Jagannath
   * @date 2020-06-02
   * @param data body data
   */
  changeVersionMandatory(data): Observable<any> {
    return this.http.put(`${devUrl}appVersion`, data);
  }
  

  getPromoterAdvantages(language): Observable<any>{
    return this.http.get(`${devUrl}promoterAdvantage?lan=`+language)
  }

  setPromoterAdvantages(data):Observable<any>{
    return this.http.post(`${devUrl}promoterAdvantage`, data)
  }
}
