import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { CLOUDINARY_BASE_URL, CLOUDINARY_IMAGE } from 'src/app/global/global';
@Component({
  selector: 'app-post-asset-dialog',
  templateUrl: './post-asset-dialog.component.html',
  styleUrls: ['./post-asset-dialog.component.scss'],
})
export class PostAssetDialogComponent implements OnInit {
  @Input() content: any;
  @Input() title: any;
  @Input() postType: any;
  @Input() description: string;
  @Input() imageClass: string;
  BASE_URL:string;
  userGalleryList: any = [];
  mediaType: string;
  constructor(private ref: NbDialogRef<PostAssetDialogComponent>,
    private store: Store<{state}>
    ) {
      this.store.select(state=>state.state.posts.awsCognitoCreds)
        .subscribe(state=> {
          this.BASE_URL = state.BASE_URL;
        })
  }

  ngOnInit() {
    this.userGalleryList = this.content;
    if(this.postType){
      this.mediaType = this.postType.toLowerCase()
    }
    console.log(this.content, this.postType, this.mediaType)
  }

  closeDialog() {
    this.ref.close();
  }
}
