import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Cloudinary } from '@cloudinary/angular-5.x';
import {
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
} from 'ng2-file-upload';
import { UsersService } from '../service/users.service';

@Component({
  selector: 'app-banner-image-cropper',
  templateUrl: './banner-image-cropper.component.html',
  styleUrls: ['./banner-image-cropper.component.scss'],
})
export class BannerImageCropperComponent implements OnInit {
  croppedImage: any = '';

  @Input() action: boolean;
  @Input() title: string;
  @Input() content: any;
  @Input() ok: string;
  @Input() cancelT: string;
  @Input() type: string;
  @Input() path: string;

  constructor(
    protected ref: NbDialogRef<BannerImageCropperComponent>,
    private cloudinary: Cloudinary,
    private service: UsersService
  ) {}
  profilePicIcon: any;
  isLoadingIcon = false;
  addUserForm: FormGroup;
  reason = '';
  width: any;
  height: any;
  submitted:boolean;
  imgTitle: any = '';
  imgAlt: any = '';
   
  ngOnInit() {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.width = event.width;
    this.height = event.height;
    console.log(event, this.ref.componentRef.instance);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  cancel() {
    this.ref.close();
  }
  get f() {
    return this.addUserForm.controls;
  }

  crop() {
    this.ref.componentRef.instance.height = this.height;
    this.ref.componentRef.instance.width = this.width;
  }
  submit() {
    if (this.content.target.id === 'websiteImageUpload') {
      if (this.height === 310 && this.width === 1166 ) {
      } else {
        alert(`Height and Width Should Be 310px and 1166px`);
        return false;
      }
    }
    this.service
      .uploadImageWithAwsCognito(this.croppedImage, this.path)
      .subscribe(
        (res: any) => {
          console.log("res",res)
          this.ref.close(res.data.url);
        },
        error => {
          console.log(error);
        }
      );
  }
}
